<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DropdownDefault from "@/components/dropdown-default";

import FormButtonSalvar from "@/components/forms/form-button-salvar/index";

import {
  required,
} from "vuelidate/lib/validators";

import {
    skillMethods,
    notificationMethods,
} from "@/state/helpers";

/**
 * Habilities component
 */
export default {
  page: {
    title: "Gerenciar Habilidades",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    DropdownDefault,
    FormButtonSalvar
  },
  data() {
    return {
      tableData: [],
      loading: true,
      title: "Gerenciar Habilidades",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Gerenciar Habilidades",
          active: true,
        },
      ],  
      showModal: false,
      tableHighlighted: 0,
      totalRows: 1,
      rows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fieldAliases: {
        id: "#",
        title: "Habilidade",
        status: "Status",
        actions: "Ações",
      },    
      form: {
        title: "",
      },   
      submitted: false,
      loadingForm: false,
      submit: false,
    }; 
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    fields() {
      return [
        { key: "id", sortable: true, label: this.fieldAliases.id, thStyle: { width: "20%" } },
        { key: "title", sortable: true, label: this.fieldAliases.title },
        { key: "status", sortable: true, label: this.fieldAliases.status, thStyle: { width: "10%" } },
        { key: "actions", sortable: true, label: this.fieldAliases.actions, thStyle: { width: "110px" } }
      ];
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  created() {
    this.clearNotifications();
    this.loadAll();
  },
  validations: {
    form: {
      title: { required },
    },
  },
  methods: {
    ...skillMethods,
    ...notificationMethods,
    async formSubmit() {
      this.submitted = true;
      this.loadingForm = true;
      this.$v.$touch();      
      if (!this.$v.$invalid) {
          await this.saveSkill(this.form).then(() => {
            this.form.title = '';
            this.loadAll();
            this.showModal = false;
            this.submitted = false;
            this.loadingForm = false;
          });
      } else {        
        this.loadingForm = false;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    handleNewCad(value) {
      this.showModal = value;
    },

    async loadAll() {
      this.loading = true;
      let registros = [];        
      registros = await this.getAllSkill();
      this.tableData = registros?.data;
      this.loading = false;
      this.totalRows = this.tableData.length;
      this.rows = this.tableData.length;
    },

    edit(obj) {
      this.showModal = true;
      setTimeout(() => {
        this.form = obj;          
      }, 200);  
    },

    async exclude(obj) {      
      await this.delete(obj.id);
      this.loadAll();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :addText="'Adicionar Nova Habilidade'" @newCad="handleNewCad" />
    <b-alert 
      v-if="notification.message" 
      show 
      variant="warning" 
      dismissible      
      @dismissed="clearNotifications()"
      >
      {{ notification.message }}
    </b-alert>
    <div class="row">
      <div class="col-12">
        <div class="card">        
          <div class="card-body">
            <h4 class="card-title">Habilidades</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar
                    <b-form-select class="form-control form-control-sm form-select form-select-sm" v-model="perPage" size="sm" :options="pageOptions"></b-form-select> Registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Pesquisar: 
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Digite algo..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-spinner
                v-if="loading"
                label="Spinning"
                variant="info"
                class="m-1"
              ></b-spinner>
              <b-table
                v-if="!loading"
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(status)="obj">
                  <div class="actions-cell">    
                    <span class="badge ms-1" :class="obj.item.status == 'A' ? 'bg-success' : 'bg-warning'">
                      <h6 class="m-0">{{obj.item.status == "A" ? "Ativo" : "Inativo"}}</h6>
                    </span>                   
                  </div>
                </template>
                <!-- Coluna "actions" personalizada -->
                <template #cell(actions)="obj">
                  <div class="actions-cell">
                    <DropdownDefault @edit="edit(obj.item)" @exclude="exclude(obj.item)"></DropdownDefault>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 

    <b-modal v-model="showModal" id="modal-dialog" title="Cadastrar Habilidade" title-class="font-18" hide-footer>
      <form class="needs-validation" @submit.prevent="formSubmit">        
        <label class="form-label" for="title">Título</label>
        <input
          id="title"
          v-model="form.title"
          type="text"
          class="form-control"
          placeholder="Digite o título da habilidade"
          value="Mark"
          :class="{
            'is-invalid': submitted && $v.form.title.$error,
          }"
        />
        <div
          v-if="submitted && $v.form.title.$error"
          class="invalid-feedback"
        >
          <span v-if="!$v.form.title.required"
            >Por favor, digite o título.</span
          >
        </div><br>
        <div class="mt-2 text-end">
            <b-button variant="light" @click="handleNewCad(false)">Fechar</b-button>
            <FormButtonSalvar 
              :parent="this"
              :loading="loadingForm"
            ></FormButtonSalvar>
          </div>
      </form>    
    </b-modal>
    <!-- end row -->
  </Layout>
</template>
